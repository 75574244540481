import axios from "axios";
import swal from "sweetalert";

axios.interceptors.response.use(
  (response) => {
    // console.warn({ response });
    return response;
  },
  (error) => {
    console.warn(error);
    if (error.response.status === 401) {
      swal({
        icon: 'error',
        title: error.response.data.message,
      }).then((response) => {
        window.location.reload();
      })
      return error;
    }
    return Promise.reject(error);
  }
);
export default axios;