// StripeWrapper.js
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Payment from './Containers/Payment';

const stripePromise = loadStripe(process.env.REACT_APP_CLIENT_LIVE_KEY);

const StripeWrapper = () => (
  <Elements stripe={stripePromise}>
    <Payment />
  </Elements>
);

export default StripeWrapper;
