import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Url } from "../../Helper";
import Http from "../../Http";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

function App({ userDetails, project_name, emirate_name, Amount, Tip, description, service }) {
  let UserData = userDetails;
  const navigate = useNavigate();

  useEffect(() => {
    fetchPublishableKey();
  }, [Amount, Tip,description]);

  const fetchPublishableKey = async () => {
    try {
      const publishableKey = process.env.REACT_APP_CLIENT_LIVE_KEY;

      if (!publishableKey) {
        console.error("No publishable key returned from the server.");
        alert("Please set your Stripe publishable API key in the .env file");
        return;
      }

      initializeStripe(publishableKey);
    } catch (error) {
      console.error("Error fetching publishable key:", error);
    }
  };
  const [text, setText] = useState('');
  const [socialButton, setButtonName] = useState('');
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const initializeStripe = async (publishableKey) => {
    let Vat = (Amount / 100) * 5; // Calculate VAT directly as a floating-point number
    let Desc = parseInt(localStorage?.getItem("Desc")) || "No Description";
    let TotalAmount = Amount + Vat + Tip;

    let amountInSubunit = (TotalAmount * 100);
    const stripe = await loadStripe(publishableKey, {
      apiVersion: "2020-08-27",
    });

    const paymentRequest = stripe.paymentRequest({
      country: "AE",
      currency: "aed",
      requestPayerName: true,
      requestPayerEmail: true,
      total: {
        label: Desc ? Desc : 'Car Washing',
        amount: amountInSubunit ? amountInSubunit : 1,
      }
    });

    const elements = stripe.elements();
    const prButton = elements.create("paymentRequestButton", {
      paymentRequest: paymentRequest,
    });

    paymentRequest.canMakePayment().then(function (result) {
      try {
        if (result && result.applePay === true) {
          buttonName("Apply Pay");
          prButton.mount("#payment-request-button");
        } else if (result && result.googlePay === true) {
          buttonName("Google Pay");
          prButton.mount("#payment-request-button");
          prButton.disabled = true;
        } else {
          setIsButtonVisible(false);
          addMessage("Google Pay & Apple Pay support not found. Check the pre-requisites above and ensure that you are in a supported browser.");
        }
      } catch (error) {
        sendErrorLog(error)
      }
    })
      .catch(function (error) {
        console.error(error);
        addMessage("Error checking payment support: " + error.message);
      });
      
    paymentRequest.on("paymentmethod", async (e) => {
      try {
        e.amount = TotalAmount;
        e.user_email = UserData?.email;
        e.project_name = project_name;
        e.emirate_name = emirate_name;
        e.OriginalAmount = Amount;
        e.tip = Tip ? Tip : 0;
        e.TotalVat = Vat;
        e.description = description ? description : 'NO DESCRIPTION ADDED';
        e.user_building = UserData?.building_name;
        e.userService = service ? service : UserData?.selectedService ? UserData?.selectedService : 'No Service Selected';
        e.userID = parseInt(localStorage?.getItem("user_id"));

        const response = await Http.post(Url + "/api/pay", e);
        let res = response.data;

        if (res.status != 200) {
          console.info("Not 200 Condiiton")
          console.warn("Got error" + res);
          e.complete('fail');
          swal({
            title: "API Error",
            text: res.msg,
            icon: "error",
          });
          return;
        } else if (res.status === 202 || res.status === '202') {
          swal({
            title: "Payment Failed!",
            text: res.msg,
            icon: "error",
          });
        } else {
        e.complete("success");
        setTimeout(() => {
          swal({
            title: "Payment Successful",
            text: "Your payment has been successfully processed. The copy of your Invoice and Receipt has been sent to your email.",
            icon: "success",
          }).then(function (response) {
            if (parseInt(localStorage?.getItem("user_id"))) {
              navigate("/customer_payment");
            } else {
              window.location.reload();
            }
          });
          e.complete("success");
        }, 2000);
      }
      } catch (err) {
        sendErrorLog(err)
      }
    });
  };

  const sendErrorLog = async (err) => {
    await Http.post(
      Url + "/api/sendErrorToLog",
      {
        "error": err.message
      }
    ).then((r) => {
      swal({
        title: "Payment failed",
        text: "Seems like your account account is not verified with Google.",
        icon: "error",
      });
    }).catch((error) => {
      console.error("Error while fetching API data:", error);
      return { error: { message: "Failed to fetch data" } };
    });
  }
  const centerTextStyle = {
    textAlign: 'center', // Set text-align to center
  };

  const addMessage = (message) => {
    const newText = message;
    setText(newText);
  };

  const buttonName = (name) => {
    const newButton = name;
    setButtonName(newButton);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div id="button_name" style={centerTextStyle}>
          <h1>{socialButton}</h1>
        </div>
        {isButtonVisible ?
          <div id="payment-request-button"></div>
          : ''}
        <div id="messages" role="alert">{text}</div>
      </header>
    </div>
  );
}

export default App;
