import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/scss/Style.scss";
import "antd/dist/reset.css";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import Home from "./Containers/Home";
import rootReducer from "./Store/reducers";
import Login from "./Containers/Login";
import ForgotPassword from "../src/Containers/ForgotPassword";
import ResetPassword from "../src/Containers/ResetPassword";
import CustomerDashboard from "./Components/Customer/Dashboard/CustomerDashboard";
import CustomerProfile from "./Components/Customer/Dashboard/CustomerProfile";
import CustomerPayment from "./Components/Customer/Dashboard/CustomerPayment";
import CustomerEditProfile from "./Components/Customer/Dashboard/CustomerProfileEdit";
import CustomerInvoice from "./Components/Customer/Dashboard/Components/Invoice";
import CustomerRecipt from "./Components/Customer/Dashboard/Components/Recipt";
import Terms from "./Containers/Customer/Terms";
import Privacy from "./Containers/Customer/Privacy";
import NotFound from "./Containers/NotFound";
import { Navigate } from "react-router-dom";
import GoogleCallBack from "./Components/Auth/Login/GoogleCallBack";
import swal from "sweetalert";
import StripeWrapper from "./StripeWrapper";
import ProtectedPaymentRoute from "./ProtectedPaymentRoute";

const root = ReactDOM.createRoot(document.getElementById("root"));

const store = createStore(rootReducer, applyMiddleware(thunk));

let url = window.location.href;
let splitteUrl = url.split("?");

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

let message = params.message;
if (message) {
  swal({
    title: message,
    icon: "info",
  }).then(() => {
    localStorage.clear();
  });
}


if (splitteUrl.length > 1 && splitteUrl[1] == "fromPage=verifyEmail") {
  localStorage.removeItem("user_id");
  localStorage.removeItem("email");
  localStorage.removeItem("user_name");
  localStorage.removeItem("token");
  localStorage.setItem("isLoggedIn", false);
  localStorage.setItem("passwordEntered", false);
  sessionStorage.clear(); //for sessionStorage
  window.location.href = "/login";
}

let isLoggedIn = localStorage.getItem("email");
let qr_token = localStorage.getItem("QRverify");

root.render(
  <Provider store={store}>
    <BrowserRouter>
      {isLoggedIn ? (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/customer_dashboard" element={<CustomerDashboard />} />
          <Route path="/customer_profile" element={<CustomerProfile />} />
          <Route
            path="/customer_editprofile"
            element={<CustomerEditProfile />}
          />
          <Route path="/customer_payment" element={<CustomerPayment />} />
          <Route path="/customer_invoice" element={<CustomerInvoice />} />
          <Route path="/customer_receipt" element={<CustomerRecipt />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route
            path="/login"
            element={<Navigate to={"/?callback=" + qr_token} />}
          />
          {/* //Monthly Payment Page */}
          <Route path="/payment" element={<ProtectedPaymentRoute element={<StripeWrapper />} />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:id" element={<ResetPassword />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/auth/google" element={<GoogleCallBack />} />
          {/* //Monthly Payment Page */}
          <Route path="/payment" element={<ProtectedPaymentRoute element={<StripeWrapper />} />} />
          <Route path="/customer_dashboard" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </BrowserRouter>
  </Provider>
);
