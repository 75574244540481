import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Space, Form, Input, Row, Col, Card, Typography } from 'antd';
const { Title } = Typography;

const CustomerProfileEdit = () => {
    const onFinish = (values) => {
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <>
            <div className="customer-editprofile">
                <Row className='contentRow'>
                    <Col xs={24} md={24} className="formSection">
                        <Card>
                            <Row>
                                <Col xs={24} md={24} className="header">
                                    <Title level={4}>Edit profile</Title>
                                </Col>
                            </Row>
                            <hr />
                            <Form
                                name="basic"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                className='infoForm'
                            >
                                <label className='formlabel'>First Name</label>
                                <Form.Item
                                    name="firstName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your first name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='First Name' />
                                </Form.Item>
                                <label className='formlabel'>Last Name</label>
                                <Form.Item
                                    name="lastName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your last name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Last Name' />
                                </Form.Item>
                                <label className='formlabel'>Mobile number</label>
                                <Form.Item
                                    name="mobile_no"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your mobile number!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Mobile Number' />
                                </Form.Item>
                                <label className='formlabel'>Building</label>
                                <Form.Item
                                    name="building"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your building name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Building Name' />
                                </Form.Item>
                                <label className='formlabel'>Apartment Number</label>
                                <Form.Item
                                    name="apartment"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your apartment number!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Apartment Number' />
                                </Form.Item>
                                <Row className='splitRow'>
                                    <Col xs={24} md={11}>
                                        <label className='formlabel'>Car</label>
                                        <Form.Item
                                            name="car"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your car name!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Car' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <label className='formlabel'>Car Model</label>
                                        <Form.Item
                                            name="carModel"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter model of your car!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Car Model' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.List name="users">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Space
                                                    key={key}
                                                    style={{
                                                        display: 'flex',
                                                        marginBottom: 8,
                                                    }}
                                                    align="baseline"
                                                >
                                                    <Row className='splitRow customborderTop'>
                                                        <Col xs={24} md={11}>
                                                            <label className='formlabel'>Car</label>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'car']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Missing car name',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input placeholder="Car" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} md={12}>
                                                            <label className='formlabel'>Car Model</label>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'carModel']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Missing Car Model',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input placeholder="Car Model" />
                                                            </Form.Item>
                                                            <Col xs={24} md={24} className="removeBtnWrapper">
                                                                <Button onClick={() => remove(name)} className="removeBtn">Remove</Button>
                                                            </Col>
                                                        </Col>
                                                    </Row>

                                                </Space>
                                            ))}
                                            <Form.Item className='AddCarBtn'>
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                    Add Car
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                                <label className='formlabel'>Plate Number</label>
                                <Form.Item
                                    name="plateNumber"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your Plate Number!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Plate Number' />
                                </Form.Item>
                                <Form.Item
                                    className='buttonSection'
                                >
                                    <Button type="primary" htmlType="submit">
                                        Update
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default CustomerProfileEdit;