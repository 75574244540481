import React from 'react';
import { Container } from '@mui/system'
import { Card, Typography, Col, Row, Collapse } from 'antd'
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
const { Title } = Typography;
const { Panel } = Collapse;

const Privacy = () => {
    const onChange = (key) => {
        console.log(key);
    }
    return (
        <>
            <Container>
                <div className='termsWrapper'>
                    <Card>
                        <Row>
                            <Col xs={24} md={24} className="modalHeader">
                                <Title level={4}>Privacy Policy</Title>
                            </Col>
                            <Col xs={24} md={24} className="privacy-body">
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p>
                                <Collapse onChange={onChange} expandIconPosition="end"
                                    expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}>
                                    <Panel header="What information do we collect about you?" key="1">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    </Panel>
                                    <Panel header="How do we use your information?" key="2">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    </Panel>
                                    <Panel header="To whom do we share your information?" key="3">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    </Panel>
                                    <Panel header="Lorem Ipsum is simply dummy text of the printing and typesetting industry." key="4">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    </Panel>
                                    <Panel header="Lorem Ipsum is simply dummy text of the printing and typesetting industry." key="5">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    </Panel>
                                    <Panel header="Lorem Ipsum is simply dummy text of the printing and typesetting industry." key="6">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    </Panel>
                                    <Panel header="Lorem Ipsum is simply dummy text of the printing and typesetting industry." key="7">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    </Panel>
                                    <Panel header="Lorem Ipsum is simply dummy text of the printing and typesetting industry." key="8">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    </Panel>
                                </Collapse>
                            </Col>
                        </Row>

                    </Card>
                </div>
            </Container>
        </>
    )
}

export default Privacy;