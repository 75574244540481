import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Url } from "../../../Helper";
import Http from "../../../Http";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
function GoogleCallBack() {
  localStorage.getItem("auth_role");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  let qr_token = localStorage.getItem("QRverify");

  const location = useLocation();
  useEffect(() => {
    setLoading(true);
    Http.get(Url + `/api/auth/google_callback${location.search}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((res) => {
      if (res?.user_status === 0) {
        setLoading(false);
        swal({
          title: "Your account has been disabled.",
          icon: "error",
          button: "Close",
          closeOnClickOutside: false,
        });
      } else if (res?.data?.google_login === true) {
        navigate("/" + "?callback=" + qr_token);
        localStorage.setItem("user_id", res.data.user_id);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user_name", res.data.name);
        localStorage.setItem("email", res.data.email);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("passwordEntered", true);
        window.location.reload();
        setLoading(false);
      } else {
        swal({
          title:'Invalid login Method!',
          text:'Your account was registered using the manual registration process. Please login with your credentials to access your account',
          icon:'error'
        }).then((res) => {
          navigate('/login');
        })
      }
    });
  }, []);

  return loading ? "" : <div>Logged In Succesffully</div>;
}
export default GoogleCallBack;
