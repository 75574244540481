import React from 'react'
import LoginLogo from '../Assets/Images/LoginLogo.png';
import { Col, Row, Card, Image, Typography } from 'antd';
import { TfiWorld } from "react-icons/tfi";

const { Title } = Typography;

const NotFound = () => {
    return (
        <>
            <div className="NotFoundWrapper">
                <div className='disabledQRSection'>
                    <Col xs={24} md={24}>
                        <div className='linkSection'>
                            <TfiWorld />
                            <a href='https://kenzieclean.ae/' >www.kenzieclean.ae</a>
                        </div>
                    </Col>
                    <Card>
                        <div className='imgWrapper'>
                            <Image src={LoginLogo} className="img-fluid" alt="Not found" preview={false} />
                        </div>
                        <Row>
                            <Col xs={24} md={24}>
                                <Title level={2}>Page Not Found!</Title>
                            </Col>
                        </Row>
                        <p>This QR code is invalid.</p>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default NotFound;