import React, { useState } from "react";
import {
  Col,
  Row,
  Input,
  Form,
  Card,
  Image,
  Button,
  Typography,
  Spin,
} from "antd";
import { Container } from "react-bootstrap";
import Logo from "../../../Assets/Images/LoginLogo.png";
import { useNavigate, useParams } from "react-router-dom";
import * as UserService from "../../../Services/UserService/UserService";
import { useDispatch } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import swal from "sweetalert";
const { Title } = Typography;

const ResetPasswordMobile = () => {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const onFinish = (values) => {
    values.token = id;
    setLoader(true);
    setOpen(true);
    dispatch(UserService.changePassword(values)).then((res) => {
      swal({
        title: "Password change successfully!",
        icon: "success",
        button: "Close",
        closeOnClickOutside: false,
      });
      setLoader(false);
      setOpen(false);
      navigate("/login");
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {loader === true ? (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <Spin />
          </Backdrop>
        </>
      ) : (
        " "
      )}
      <div className="forgotPwdWrapper">
        <Container>
          <Row>
            <Col xs={24} md={24}>
              <Card className="forgotCard">
                <Row>
                  <Col xs={24} md={24}>
                    <div className="logoSection">
                      <Image
                        src={Logo}
                        preview={false}
                        alt="Not Found"
                        width={80}
                      />
                    </div>
                    <Col xs={24} md={24} className="header resetPwdHeader">
                      <Title level={4}>Reset Your Password</Title>
                      <p>Please enter a new password for your account.</p>
                    </Col>
                    <Col xs={24} md={24}>
                      <Form
                        name="basic"
                        initialValues={{
                          remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                      >
                        <Form.Item
                          name="newPassword"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your new password!",
                            },
                          ]}
                        >
                          <Input.Password placeholder="Password" />
                        </Form.Item>
                        <Form.Item
                          name="confirmPassword"
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                          ]}
                        >
                          <Input.Password placeholder="Confirm Password" />
                        </Form.Item>
                        <Col xs={24} md={24} className="buttonSection">
                          <Form.Item>
                            <Button type="primary" htmlType="submit">
                              Reset Password
                            </Button>
                          </Form.Item>
                        </Col>
                      </Form>
                    </Col>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ResetPasswordMobile;
