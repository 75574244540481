import React, { useState } from "react";
import {
  Col,
  Row,
  Input,
  Form,
  Card,
  Image,
  Button,
  Typography,
  Spin,
} from "antd";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from "../../../Assets/Images/LoginLogo.png";
import * as UserService from "../../../Services/UserService/UserService";
import { useDispatch } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import { AiOutlineArrowLeft } from "react-icons/ai";
import swal from "sweetalert";
const { Title } = Typography;

const ForgotPasswordMobile = () => {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = (values) => {
    setLoader(true);
    setOpen(true);
    dispatch(UserService.forgotPassword(values)).then((res) => {
      swal({
        title: "Link Sent!",
        text: "Please check your registered email address",
        icon: "success",
        button: "Close",
        closeOnClickOutside: false,
      });
      setLoader(false);
      setOpen(false);
      navigate("/login");
    });
    setLoader(false);
    setOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {loader === true ? (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <Spin />
          </Backdrop>
        </>
      ) : (
        " "
      )}
      <div className="forgotPwdWrapper">
        <Container>
          <Row>
            <Col xs={24} md={24}>
              <Card className="forgotCard">
                <Row>
                  <Col xs={24} md={24}>
                    <div className="logoSection">
                      <Image
                        src={Logo}
                        preview={false}
                        alt="Not Found"
                        width={80}
                      />
                    </div>
                    <Col xs={24} md={24} className="header">
                      <Title level={4}>Forgot Your Password</Title>
                      <p>
                        Please enter the email address you'd like your password
                        reset information sent to.
                      </p>
                    </Col>
                    <Col xs={24} md={24}>
                      <Form
                        name="basic"
                        style={{
                          maxWidth: 600,
                        }}
                        initialValues={{
                          remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                      >
                        <label>Verify email address</label>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your email address!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter your registered email address"
                            className="forgotInput"
                          />
                        </Form.Item>
                        <Col xs={24} md={24} className="buttonSection">
                          <Form.Item>
                            <Button type="primary" htmlType="submit">
                              Request Reset Link
                            </Button>
                          </Form.Item>
                        </Col>
                      </Form>
                      <Col xs={24} md={24} className="backLogin">
                        <AiOutlineArrowLeft />
                        <a href="/login">Back To Login</a>
                      </Col>
                    </Col>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ForgotPasswordMobile;
