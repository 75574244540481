import React, { useEffect, useState } from "react";
import Sidebar from "../../../Containers/Customer/Sidebar";
import { Tabs, Image, Row, Col, Card, Typography, Spin } from "antd";
import Avatar from "../../../Assets/Images/Avatar.png";
import PersonalInfo from "./Components/PeronalInfo";
import Cars from "./Components/Cars";
import * as UserService from "../../../Services/UserService/UserService";
import Backdrop from "@mui/material/Backdrop";
import { useDispatch } from "react-redux";
import { image } from "../../../Helper";
const { Title } = Typography;

const CustomerProfile = () => {
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);
  const user_email = localStorage.getItem("email");

  useEffect(() => {
    getUserInformation();
  }, []);

  const getUserInformation = () => {
    setLoader(true);
    setOpen(true);
    dispatch(UserService.getUserProfile(user_email)).then((res) => {
      setUserData(res);
      setLoader(false);
      setOpen(false);
    });
  };

  const items = [
    {
      key: "1",
      label: `Personal Info`,
      children: <PersonalInfo getUserInformation={getUserInformation} />,
    },
    {
      key: "2",
      label: `Manage Vehicles`,
      children: <Cars />,
    },
  ];
  
  return (
    <>
      <Sidebar />
      {loader === true ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <Spin />
        </Backdrop>
      ) : (
        <div className="customer-profile">
          <Row className="contentRow">
            <Col xs={24} md={24} className="formSection">
              <Card>
                <Row>
                  <Col xs={24} md={24} className="header">
                    <div className="imgSection">
                      <Image
                        src={
                          userData.profile_img
                            ? image + userData.profile_img
                            : Avatar
                        }
                        alt="Not found"
                        className="img-fluid"
                        preview={false}
                      />
                    </div>
                  </Col>
                  <div className="informationContent">
                    <Title level={4} className="nameSection">
                      {userData?.first_name} {userData?.last_name}
                    </Title>
                    <Col xs={24} md={24}>
                      <Tabs defaultActiveKey="1" items={items} />
                    </Col>
                  </div>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default CustomerProfile;
