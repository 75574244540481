import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Typography, Row, Col, Button, Spin } from "antd";
import { useDispatch } from "react-redux";
import * as UserService from "../Services/UserService/UserService";
import Backdrop from "@mui/material/Backdrop";
import { image } from "../Helper";
const { Title } = Typography;

export default function ControlledCarousel({ handleCancelMenu, qrData }) {
  const dispatch = useDispatch();
  const [menuImageData, setMenuImageData] = useState([]);
  const [index, setIndex] = useState(0);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    getMenuImages();
  }, []);

  const getMenuImages = () => {
    setLoader(true);
    setOpen(true);
    if (qrData == undefined) {
      dispatch(UserService.get_service_image()).then((res) => {
        setMenuImageData(res.data);
        setLoader(false);
        setOpen(false);
      });
    } else {
      if (qrData.service_image.length > 0) {
        setMenuImageData(qrData.service_image);
        setLoader(false);
        setOpen(false);
      } else {
        setMenuImageData("");
        setLoader(false);
        setOpen(false);
      }
    }
  };

  return (
    <>
      <div className="modalHeader">
        <Title level={2}>Services</Title>
        <Button onClick={handleCancelMenu}>X</Button>
      </div>
      <hr />
      <Row className="carousalWrapper">
        <Col xs={24} md={24}>
          {loader === true ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <Spin />
            </Backdrop>
          ) : (
            <>
              {menuImageData.length === 0 ? (
                <div className="NoServiceWrapper">
                  <h5>No Images Found</h5>
                </div>
              ) : (
                <Carousel activeIndex={index} onSelect={handleSelect}>
                  {menuImageData.map((img) => (
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={image + img?.image_path}
                        alt="First slide"
                      />
                      <Carousel.Caption>
                        <h3>{img.description}</h3>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))}
                </Carousel>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
