import { Url } from "../../Helper";
import Http from "../../Http";
import swal from "sweetalert";

export function saveUserDetails(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/user_detail", value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function getLandingPageDetails() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(Url + "/api/get_landing_page_data")
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function check_existing_user(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/check_existing_user", { email: value })
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function get_service_image() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(Url + "/api/get_service_image")
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function get_service_image_by_id(id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(Url + "/api/get_service_image_by_id/" + id)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function get_vehicle_detail() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(Url + "/api/get_vehicle_detail")
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function register_user(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/register_user", value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function login_user(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/login_user", value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function Scanned_value(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/qr_code_detail", { id: value })
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function payment_data(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/payment_data", value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function getUserProfile(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/get_customer_detail", { user_email: value })
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function updateCustomerDetails(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/update_customer_detail", value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: err.response.data.message,
            icon: "error",
          });
        });
    });
}

export function forgotPassword(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/forget_password", value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          swal({
            title: "Error!",
            text: "Invalid Credentials",
            icon: "error",
            button: "Try Again",
            closeOnClickOutside: false,
          });
          return reject(err);
        });
    });
}

export function changePassword(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/change_password", value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function resetPassword(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/reset_password", value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function addCar(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/store_customer_car", value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function update_car(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/update_customer_car", value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function delete_car(id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/delete_customer_car", { id })
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function pay(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/pay", { data })
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function check_existing_user_password(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/check_existing_user_password", value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function updateProfile(user_id, value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/upload_profile_image/" + user_id, value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function check_vehicle(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/fetch-modals", { car_id: value })
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function check_vehicle_variant(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/fetch-variant", { car_modal_id: value })
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function check_existing_customer(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/check_existing_customer", { email: value })
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function update_user_information(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/update_user_information",  value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function reSendMail(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/reverify_user_mail", {email: value})
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function getServiceList() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(Url + "/api/services")
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function check_existing_user_byID(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/get_customer_data", value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}