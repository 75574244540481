import React, { useEffect, useState } from "react";
import Sidebar from "../../../Containers/Customer/Sidebar";
import {
  Row,
  Col,
  Card,
  Typography,
  Table,
  Button,
  Modal,
  Input,
  Dropdown,
  DatePicker,
  Form,
  Spin,
} from "antd";
import { useDispatch } from "react-redux";
import * as UserService from "../../../Services/UserService/UserService";
import { AiFillFilter } from "react-icons/ai";
import Backdrop from "@mui/material/Backdrop";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
const { Title } = Typography;
const dateFormat = "YYYY/MM/DD";

const CustomerPayment = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const user_email = localStorage.getItem("email");
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filteredPayments, setFilteredPayments] = useState();
  const [filteredInfo, setFilteredInfo] = useState({});
  const [form] = Form.useForm();
  let page = 1;

  useEffect(() => {
    setLoader(true);
    setOpen(true);
    dispatch(UserService.getUserProfile(user_email)).then((res) => {
      setPaymentInfo(res.payment);
      setLoader(false);
      setOpen(false);
    });
  }, []);

  const showModal = (id) => {
    setIsModalOpen(true);
    const item = paymentInfo.find((item) => item.id === id);
    setFilteredData(item);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const triggerVisible = () => {
    if (visible === true) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };

  const onChangeStartDate = (date, dateString) => {
    setFilterStartDate(moment(dateString).format("YYYY/MM/DD"));
    let EndDate = filterEndDate ? filterEndDate : moment().format("YYYY/MM/DD");
    FilterAccordingDate(moment(dateString).format("YYYY/MM/DD"), EndDate);
  };

  const onChangeEndDate = (date, dateString) => {
    setFilterEndDate(moment(dateString).format("YYYY/MM/DD"));
    let startDate = filterStartDate
      ? filterStartDate
      : moment().format("YYYY/MM/DD");
    FilterAccordingDate(startDate, moment(dateString).format("YYYY/MM/DD"));
  };

  const FilterAccordingDate = (startdate, enddate) => {
    let datefilter = [];
    paymentInfo.forEach((paymentInfo) => {
      if (
        moment(paymentInfo.date_of_payment).isSameOrAfter(moment(startdate)) &&
        moment(paymentInfo.date_of_payment).isSameOrBefore(moment(enddate))
      ) {
        datefilter.push(paymentInfo);
      }
    });
    setFilteredPayments(datefilter);
  };

  let old_date = filteredData?.date_of_payment;
  let newDate = moment(old_date).format("DD/MM/YYYY");

  const columns = [
    {
      title: "S.No",
      dataIndex: "sNo",
      key: "sNo",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      fixed: "left",
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.id).toLowerCase().includes(value.toLowerCase()) ||
          String(record.date_of_payment)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.payment_amount)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.building_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.project_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.emirate_name)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date_of_payment",
      key: "date_of_payment",
      render: (text, record) => {
        let formatted_date = moment(text).format("DD/MM/YYYY");
        return formatted_date;
      },
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
    },
    {
      title: "Amount",
      dataIndex: "payment_amount",
      key: "payment_amount",
      render: (text, record) => {
        return text?.toLocaleString();
      },
    },
    {
      title: "Building",
      dataIndex: "building_name",
      key: "building_name",
    },
    {
      title: "Project",
      dataIndex: "project_name",
      key: "project_name",
    },
    {
      title: "Emirate",
      dataIndex: "emirate_name",
      key: "emirate_name",
      filters: [
        {
          text: "Abu Dhabi",
          value: "Abu Dhabi",
        },
        {
          text: "Ajman",
          value: "Ajman",
        },
        {
          text: "Dubai",
          value: "Dubai",
        },
        {
          text: "Fujairah",
          value: "Fujairah",
        },
        {
          text: "Ras Al Khaimah",
          value: "Ras Al Khaimah",
        },
        {
          text: "Sharjah",
          value: "Sharjah",
        },
        {
          text: "Um Al Quwain",
          value: "Um Al Quwain",
        },
      ],
      filteredValue: filteredInfo.emirate_name || null,
      onFilter: (value, record) => record.emirate_name.includes(value),
    },
    {
      title: "Details",
      dataIndex: "Details",
      key: "Details",
      render: (text, record) => (
        <Button
          onClick={() => showModal(record.id)}
          className="viewDetailsButton"
        >
          View
        </Button>
      ),
    },
  ];

  const handleReset = () => {
    form.resetFields();
    setFilteredPayments("");
    setVisible(false);
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          <div className="DatepickerWrapper">
            <Form form={form}>
              <Form.Item name="date">
                <div className="StartWrapper">
                  <h4>Start Date</h4>
                  <DatePicker
                    onChange={onChangeStartDate}
                    name="start_date"
                    placeholder="Date From"
                    format={dateFormat}
                  />
                </div>
              </Form.Item>
              <Form.Item name="date">
                <div className="endWrapper">
                  <h4>End Date</h4>
                  <DatePicker
                    name="end_date"
                    onChange={onChangeEndDate}
                    placeholder="Date To"
                    format={dateFormat}
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <div className="resetWrapper">
                  <Button type="primary" onClick={handleReset}>
                    Reset
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </>
      ),
    },
  ];

  const setInvoiceID = () => {
    localStorage.setItem("invoiceID", filteredData?.id);
  };

  const setReciptID = () => {
    localStorage.setItem("receiptID", filteredData?.id);
  };

  const onChangeFilter = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
  };

  return (
    <>
      <Sidebar />
      <div className="customer-payment">
        <Row className="contentRow">
          <Col xs={24} md={24} className="tableSection">
            <Card>
              <Row>
                <Col xs={24} md={24} className="header">
                  <Title level={4}>Payment History</Title>
                </Col>
              </Row>
              <Row className="filterSearchWrapper">
                <Col xs={4} md={2} className="filterSection">
                  <Dropdown
                    menu={{
                      items,
                    }}
                    visible={visible}
                    placement="bottom"
                    onClick={() => triggerVisible()}
                    arrow
                    trigger={["click"]}
                  >
                    <Button>
                      <AiFillFilter />
                    </Button>
                  </Dropdown>
                </Col>
                <Col xs={20} md={22} className="searchSection">
                  <Input
                    className="formControl"
                    suffix={<SearchOutlined />}
                    allowClear
                    placeholder="Search..."
                    onSearch={(value) => {
                      setSearchedText(value);
                    }}
                    onChange={(e) => {
                      setSearchedText(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Col xs={24} md={24} className="tableWrapper">
                {loader === true ? (
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={open}
                  >
                    <Spin />
                  </Backdrop>
                ) : (
                  <Table
                    dataSource={
                      filteredPayments ? (
                        filteredPayments
                      ) : paymentInfo ? (
                        paymentInfo
                      ) : (
                        <Backdrop
                          sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                          }}
                          open={open}
                        >
                          <Spin />
                        </Backdrop>
                      )
                    }
                    columns={columns}
                    showSorterTooltip={false}
                    rowKey={(record) => record.paymentInfo}
                    onChange={onChangeFilter}
                  />
                )}
              </Col>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        className="detailModal"
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modalHeader">
              <Title level={4}>Payment Details</Title>
              <Button onClick={handleCancel}>X</Button>
            </div>
          </Col>
          <Col xs={24} md={24}>
            <label className="inputLabel">Transaction ID</label>
            <Input value={filteredData?.id} readOnly name="id" />
          </Col>
          <Col xs={24} md={24}>
            <label className="inputLabel">Stripe Transaction ID</label>
            <Input value={filteredData?.transaction_id} readOnly name="id" />
          </Col>
          <Col xs={24} md={24}>
            <label className="inputLabel">Date</label>
            <Input value={newDate} readOnly />
          </Col>
          <Col xs={24} md={24}>
            <label className="inputLabel">Amount</label>
            <Input value={filteredData?.original_amount} readOnly />
          </Col>
          <Col xs={24} md={24}>
            <label className="inputLabel">Tip</label>
            <Input value={filteredData?.tip} readOnly />
          </Col>
          <Col xs={24} md={24}>
            <label className="inputLabel">VAT</label>
            <Input value={filteredData?.total_vat} readOnly />
          </Col>
          <Col xs={24} md={24}>
            <label className="inputLabel">Total Amount</label>
            <Input value={filteredData?.payment_amount} readOnly />
          </Col>
          <Col xs={24} md={24}>
            <label className="inputLabel">Payment Method</label>
            <Input value="Stripe" readOnly />
          </Col>
          <Col xs={24} md={24}>
            <label className="inputLabel">Building Name</label>
            <Input value={filteredData?.building_name} readOnly />
          </Col>
          <Col xs={24} md={24} className="buttonSection">
            <a
              className="btn invoiceBtn"
              target="_black"
              rel="noreferrer"
              href="/customer_invoice"
              onClick={setInvoiceID}
            >
              Invoice
            </a>
            <a
              className="btn reciptBtn"
              target="_black"
              rel="noreferrer"
              href="/customer_receipt"
              onClick={setReciptID}
            >
              Receipt
            </a>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CustomerPayment;
