import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedPaymentRoute = ({ element }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customerId = searchParams.get('customer_id');
    const invoiceId = searchParams.get('inv');

    if (!customerId && !invoiceId) {
        // Redirect to a different page if neither customer_id nor inv is present
        return <Navigate to="/not-found" replace />;
    }

    // Render the wrapped element if either customer_id or inv is present
    return element;
};

export default ProtectedPaymentRoute;
