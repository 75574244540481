import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Form,
  Row,
  Col,
  Select,
  Typography,
  Card,
  Spin,
} from "antd";
import * as UserService from "../../../../Services/UserService/UserService";
import { useDispatch } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import {
  EditOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import swal from "sweetalert";
import axios from "../../../../Http";
import { Url } from "../../../../Helper";

const { Title } = Typography;
const Option = Select.Option;

const Cars = () => {
  const [addCarSection, setAddCarSection] = useState(1);
  const dispatch = useDispatch();
  const user_email = localStorage.getItem("email");
  const [userData, setUserData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [userCar, setUserCar] = useState([]);
  const [plateSource, setPlateSource] = useState([]);
  const [plateCode, setPlateCode] = useState([]);
  const [carList, setCarList] = useState([]);
  const [selectedCar, setSelectedCar] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [userID, setUserID] = useState();
  const [runOnce, setRunOnce] = useState(true);
  const [NoService, setNoService] = useState(false);
  const [serviceList, setServicesList] = useState([]);
  const [selectedService, setSelectedService] = useState(
    selectedCar?.services ? selectedCar?.services : userData.services
  );

  useEffect(() => {
    axios.get(Url + "/api/services", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        setServicesList(response?.data);
      })
      .catch((error) => console.error(error));
  }, []);

  const onFinish = (values) => {
    if (runOnce === true) {
      values.id = userID;
      values.service = selectedService ? selectedService : "";
      setLoader(true);
      setOpen(true);
      dispatch(UserService.addCar(values)).then((response) => {
        if (response.status === "success") {
          swal({
            title: "Vehicle Added Successfully!",
            icon: "success",
          });
          toggleView();
          getUserInfo();
          setSelectedService();
        } else {
          swal({
            title: "Error!",
            icon: "error",
          });
        }
      });
      setLoader(false);
      setOpen(false);
      setRunOnce(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo?.values);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const handleChangeService = (value) => {
    setSelectedService(value);
    if (value === "") {
      setNoService(true);
    } else {
      setNoService(false);
    }
  };

  const toggleView = () => {
    setRunOnce(true);
    if (addCarSection === 1) {
      setAddCarSection(2);
    } else {
      setAddCarSection(1);
    }
  };

  useEffect(() => {
    getUserInfo();
    verifyVehicleInfo();
  }, []);

  const getUserInfo = () => {
    setLoader(true);
    setOpen(true);
    dispatch(UserService.getUserProfile(user_email)).then((res) => {
      setUserData(res);
      setUserCar(res.car);
      setLoader(false);
      setOpen(false);
      setUserID(res?.id);
    });
  };

  const verifyVehicleInfo = (value) => {
    dispatch(UserService.get_vehicle_detail(value))
      .then((response) => {
        setPlateCode(response?.plate_code);
        setPlateSource(response?.source);
        setCarList(response?.car);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const updateCar = (id, name, model) => {
    setAddCarSection(3);
    const item = userCar.find((item) => item.id === id);
    setSelectedCar(item);
    CheckVehicle(name);
    checkModel(model);
  };

  const deleteCar = (e) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this vehicle.",
      icon: "warning",
      buttons: ["Go Back", "Remove"],
      dangerMode: true,
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(UserService.delete_car(e)).then((response) => {
          swal("Vehcile Removed Successfully ", {
            icon: "success",
          });
          getUserInfo();
        });
      } else {
        swal("Vehicle Not Deleted!", {
          icon: "info",
        });
      }
    });
  };

  const onFinishUpdate = (value) => {
    if (NoService === true) {
      swal({
        icon: "error",
        title: "Please select a service type.",
      });
    } else {
      value.id = selectedCar?.id;
      value.service = selectedService
        ? selectedService
        : selectedCar?.services ? selectedCar?.services : selectedService;
      dispatch(UserService.update_car(value))
        .then((response) => {
          swal({
            title: "Vehicle Details Updated!",
            icon: "success",
          });
          getUserInfo();
          setAddCarSection(1);
          setSelectedService();

        })
        .catch((err) => {
          swal({
            title: "Error!",
            icon: "error",
          });
        });
    }
  };

  const CheckVehicle = (value) => {
    setLoader(true);
    setOpen(true);
    let vehicle = value;
    dispatch(UserService.check_vehicle(vehicle))
      .then((response) => {
        setVehicleModels(response?.data);
        setLoader(false);
        setOpen(false);
      })
      .catch((err) => {
        setOpen(false);
        setLoader(false);
      });
  };

  const checkModel = (value) => {
    setLoader(true);
    setOpen(true);
    let vehicle = value;
    dispatch(UserService.check_vehicle_variant(vehicle))
      .then((response) => {
        setLoader(false);
        setOpen(false);
      })
      .catch((err) => {
        setOpen(false);
        setLoader(false);
      });
  };

  const renderID = (e, userData) => {
    let findService = serviceList?.find((item) => parseInt(item.id) === parseInt(e.services) || parseInt(item.id) === parseInt(userData.service_id));
    if (findService !== undefined) {
      return findService?.name;
    } else {
      let findService = serviceList?.find((item) => item.name == userData?.service);
      return findService?.name;
    }

  }

  const findSelectedService = (data) => {
    let findService = serviceList?.find((item) => item.id == data);
    return findService?.name;
  }

  return (
    <>
      {loader === true ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <Spin />
        </Backdrop>
      ) : (
        ""
      )}
      <Row>
        {addCarSection === 1 ? (
          <Col xs={24} md={24}>
            {userCar?.map((e, i) => {
              return (
                <Card className="existingCar mb-3" id={i}>
                  <div className="cardHeader">
                    <Title level={4}>
                      {e.car_make} - {e.car_model}
                    </Title>
                    <div className="CarsButtons">
                      <Button
                        onClick={() => updateCar(e.id, e.car_make, e.car_model)}
                        className="editIcon mr-2"
                      >
                        <EditOutlined />
                      </Button>
                      <Button
                        onClick={() => deleteCar(e.id)}
                        className="deleteIcon mr-2"
                      >
                        <DeleteOutlined />
                      </Button>
                    </div>
                  </div>
                  <div className="cardBody">
                    <Row>
                      <Col xs={12} md={24}>
                        <div>
                          <label>Vehicle Color</label>
                          <Title level={4}>{e.color}</Title>
                        </div>
                        <div>
                          <label>Plate Number</label>
                          <Title level={4}>{e.plate_number}</Title>
                        </div>
                      </Col>
                      <Col xs={12} md={24}>
                        <div>
                          <label>Plate Source</label>
                          <Title level={4}>{e.source}</Title>
                        </div>
                        <div>
                          <label>Plate Code</label>
                          <Title level={4}>{e.plate_code}</Title>
                        </div>
                      </Col>
                      <Col xs={12} md={24}>
                        <div>
                          <label>Parking Number</label>
                          <Title level={4}>
                            {e.parking_number ? e.parking_number : "NA"}
                          </Title>
                        </div>
                      </Col>
                      <Col xs={12} md={24}>
                        <div>
                          <label>Service</label>
                          <Title level={4}>
                            {renderID(e, userData)}
                          </Title>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })}
            <div className="addCarSection">
              <Button type="button" onClick={toggleView}>
                Add Another Vehicle
              </Button>
            </div>
          </Col>
        ) : addCarSection === 2 ? (
          <Col xs={24} md={24}>
            <div className="carCard">
              <div className="formHeader">
                <ArrowLeftOutlined onClick={toggleView} />
                <Title level={4}>Add a new Vehicle</Title>
              </div>
              <hr />
              <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className="carForm"
              >
                <Row className="splitRow">
                  <Col xs={24} md={24}>
                    <label className="formlabel">
                      Vehicle Make<span className="star">*</span>
                    </label>
                    <Form.Item
                      name="car_make"
                      rules={[
                        {
                          required: true,
                          message: "Please select Vehicle!",
                        },
                      ]}
                    >
                      <Select
                        defaultValue="Please Select Vehicle"
                        onChange={(e) => CheckVehicle(e)}
                      >
                        {carList.map((x, i) => {
                          return (
                            <>
                              <Option value={x.name}>{x.name}</Option>
                            </>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24}>
                    <label className="formlabel">
                      Vehicle Model<span className="star">*</span>
                    </label>
                    <Form.Item
                      name="car_model"
                      rules={[
                        {
                          required: true,
                          message: "Please select Vehicle Model!",
                        },
                      ]}
                    >
                      <Select
                        defaultValue="Please Select Vehicle"
                        onChange={(e) => checkModel(e)}
                      >
                        {vehicleModels.map((x, i) => {
                          return (
                            <>
                              <Option value={x.name}>{x.name}</Option>
                            </>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24}>
                    <label className="formlabel">
                      Color<span className="star">*</span>
                    </label>
                    <Form.Item
                      name="color"
                      rules={[
                        {
                          required: true,
                          message: "Please enter color of your Vehicle!",
                        },
                      ]}
                    >
                      <Input placeholder="Color" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24}>
                    <label className="formlabel">
                      Plate Number<span className="star">*</span>
                    </label>
                    <Form.Item
                      name="plate_number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Plate Number!",
                        },
                      ]}
                    >
                      <Input placeholder="Plate Number" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24}>
                    <label className="formlabel">
                      Plate Source<span className="star">*</span>
                    </label>
                    <Form.Item
                      name="source"
                      rules={[
                        {
                          required: true,
                          message: "Please select plate source!",
                        },
                      ]}
                    >
                      <Select
                        defaultValue="Please Select Plate Source"
                        onChange={handleChange}
                      >
                        {" "}
                        {plateSource.map((x, i) => {
                          return (
                            <>
                              <Option value={x.name}>{x.name}</Option>
                            </>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24}>
                    <label className="formlabel">
                      Plate Code<span className="star">*</span>
                    </label>
                    <Form.Item
                      name="plate_code"
                      rules={[
                        {
                          required: true,
                          message: "Please select Plate Code!",
                        },
                      ]}
                    >
                      <Select
                        defaultValue="Please Select Plate Code"
                        onChange={handleChange}
                      >
                        {" "}
                        {plateCode.map((x, i) => {
                          return (
                            <>
                              <Option value={x.name}>{x.name}</Option>
                            </>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24}>
                    <label className="formlabel">
                      Service<span className="star">*</span>
                    </label>
                    <Form.Item
                      name="service"
                      rules={[
                        {
                          required: true,
                          message: "Please select service!",
                        },
                      ]}
                    >
                      <Select
                        defaultValue="Please Select Service"
                        onChange={handleChangeService}
                      >
                        <option value="" selected>
                          Please Select Your Car Wash Service
                        </option>
                        {serviceList?.map((item) => (
                          <option className="OptionList" value={item.id}>
                            {item?.name}
                          </option>
                        ))}
                      </Select>
                    </Form.Item>
                    <div>
                      {selectedService === "Custom Wash" ? (
                        <div className="infoMsg">
                          <p>
                            <InfoCircleOutlined />
                            One of our team will be in touch to confirm your
                            request
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                  <Col xs={24} md={24}>
                    <label className="formlabel">Parking Number</label>
                    <Form.Item name="parking_number">
                      <Input placeholder="Parking Number" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item className="buttonSection">
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        ) : (
          <>
            <Col xs={24} md={24}>
              <div className="carCard">
                <div className="formHeader">
                  <ArrowLeftOutlined onClick={toggleView} />
                  <Title level={4}>Update Vehicle Details</Title>
                </div>
                <hr />
                <Form
                  name="basic"
                  onFinish={onFinishUpdate}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  className="carForm"
                >
                  <Row className="splitRow">
                    <Col xs={24} md={24}>
                      <label className="formlabel">Vehicle Make</label>
                      <Form.Item
                        name="car"
                        initialValue={selectedCar?.car_make}
                        rules={[
                          {
                            required: true,
                            message: "Please select Vehicle!",
                          },
                        ]}
                      >
                        <Select onChange={CheckVehicle} disabled>
                          {carList.map((x, i) => {
                            return (
                              <>
                                <Option value={x.name}>{x.name}</Option>
                              </>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                      <label className="formlabel">Vehicle Model</label>
                      <Form.Item
                        name="car_model"
                        initialValue={selectedCar?.car_model}
                        rules={[
                          {
                            required: true,
                            message: "Please select Vehicle Model!",
                          },
                        ]}
                      >
                        <Select
                          defaultValue="Please Select Vehicle"
                          onChange={(e) => checkModel(e)}
                        >
                          {vehicleModels.map((x, i) => {
                            return (
                              <>
                                <Option value={x.name}>{x.name}</Option>
                              </>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                      <label className="formlabel">Color</label>
                      <Form.Item
                        name="color"
                        initialValue={selectedCar?.color}
                        rules={[
                          {
                            required: true,
                            message: "Please enter color of your car!",
                          },
                        ]}
                      >
                        <Input placeholder="Color" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                      <label className="formlabel">Plate Number</label>
                      <Form.Item
                        name="plate_number"
                        initialValue={selectedCar?.plate_number}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Plate Number!",
                          },
                        ]}
                      >
                        <Input placeholder="Plate Number" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                      <label className="formlabel">Plate Source</label>
                      <Form.Item
                        name="plate_source"
                        initialValue={selectedCar?.source}
                        rules={[
                          {
                            required: true,
                            message: "Please select plate source!",
                          },
                        ]}
                      >
                        <Select onChange={handleChange}>
                          {" "}
                          {plateSource.map((x, i) => {
                            return (
                              <>
                                <Option value={x.name}>{x.name}</Option>
                              </>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                      <label className="formlabel">Plate Code</label>
                      <Form.Item
                        name="plate_code"
                        initialValue={selectedCar?.plate_code}
                        rules={[
                          {
                            required: true,
                            message: "Please select Plate Code!",
                          },
                        ]}
                      >
                        <Select onChange={handleChange}>
                          {" "}
                          {plateCode.map((x, i) => {
                            return (
                              <>
                                <Option value={x.name}>{x.name}</Option>
                              </>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                      <label className="formlabel">Service</label>
                      <Form.Item name="service">
                        <Select
                          defaultValue={
                            selectedCar?.services !== null
                              ? findSelectedService(selectedCar?.services)
                              : userData?.service !== null ? userData?.service : selectedService !== undefined ? selectedService : userData?.service_id
                          }
                          onChange={handleChangeService}
                        >
                          <option value="" selected>
                            Please Select Your Car Wash Service
                          </option>
                          {serviceList?.map((item) => (
                            <option className="OptionList" value={item.id}>
                              {item?.name}
                            </option>
                          ))}
                          {/* <option
                            value="3 Washes Per Week"
                            className="OptionList"
                          >
                            3 Washes Per Week
                          </option>
                          <option
                            value="2 Washes Per Week"
                            className="OptionList"
                          >
                            2 Washes Per Week
                          </option>
                          <option
                            value="1 Wash Per Week"
                            className="OptionList"
                          >
                            1 Wash Per Week
                          </option>
                          <option
                            value="One Time Wash Only"
                            className="OptionList"
                          >
                            One Time Wash Only
                          </option>
                          <option value="Custom Wash" className="OptionList">
                            Custom Wash
                          </option> */}
                        </Select>
                        {selectedCar?.services === "Custom Wash" ||
                          userData?.service === "Custom Wash" ? (
                          <div className="infoMsg">
                            <p>
                              <InfoCircleOutlined />
                              One of our team will be in touch to confirm your
                              request
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                      <label className="formlabel">Parking Number</label>
                      <Form.Item
                        initialValue={selectedCar?.parking_number}
                        name="parking_number"
                      >
                        <Input placeholder="Parking Number" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item className="buttonSection">
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default Cars;
