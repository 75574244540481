import React, { useEffect, useState } from "react";
import Logo from "../../../../Assets/Images/LoginLogo.png";
import { useDispatch } from "react-redux";
import * as UserService from "../../../../Services/UserService/UserService";
import Backdrop from "@mui/material/Backdrop";
import moment from "moment";
import html2pdf from "html2pdf.js";
import { Spin } from "antd";

const Invoice = () => {
  const [userInfo, setUserInfo] = useState([]);
  const [paymentById, setPaymentById] = useState([]);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const user_email = localStorage.getItem("email");
  const invoiceID = localStorage.getItem("invoiceID");
  const dispatch = useDispatch();

  useEffect(() => {
    showModalInvoice();
  }, []);

  const showModalInvoice = () => {
    setOpen(true);
    setLoader(true);
    dispatch(UserService.getUserProfile(user_email)).then((res) => {
      setUserInfo(res);
      FilterPayment(res?.payment);
      setOpen(false);
      setLoader(false);
    });
  };

  const exportPdf = () => {
    const element = document.getElementById("capture");
    const options = {
      margin: [0.5, 0.5],
      filename: "Invoice.pdf",
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2, logging: true },
      jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
    };

    html2pdf().set(options).from(element).save();
  };

  const FilterPayment = (data) => {
    let item = data?.find(({ id }) => id == invoiceID);
    setPaymentById(item);
  };

  return (
    <>
      {loader === true ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <Spin />
        </Backdrop>
      ) : (
        <>
          <div className="table-responsive" id="capture">
            <table className="invoiceModal">
              <tbody>
                <tr>
                  <td>
                    <table style={{ width: "100%", marginBottom: "0.1rem" }}>
                      <tbody>
                        <tr>
                          <td className="w_70 sm_wdth_6">
                            <p className="largeText">
                              Kenzie Building Cleaning Services EST
                            </p>
                            <p
                              style={{
                                marginBottom: "0rem",
                                fontSize: "15px",
                                color: "#000",
                              }}
                            >
                              {" "}
                              PO Box 454592
                            </p>
                            <p
                              style={{
                                marginBottom: "0rem",
                                fontSize: "15px",
                                color: "#000",
                              }}
                            >
                              Dubai, U.A.E
                            </p>
                            <p
                              style={{
                                marginBottom: "0rem",
                                fontSize: "15px",
                                color: "#000",
                              }}
                            >
                              +971 600565693{" "}
                            </p>
                            <p
                              style={{
                                marginBottom: "0rem",
                                fontSize: "15px",
                                color: "#000",
                              }}
                            >
                              {" "}
                              billing@kenzieclean.ae
                            </p>
                            <p
                              style={{
                                marginBottom: "0rem",
                                fontSize: "15px",
                                color: "#000",
                              }}
                            >
                              www.kenzieclean.ae
                            </p>
                            <p
                              style={{
                                marginBottom: "0rem",
                                fontSize: "15px",
                                color: "#000",
                              }}
                            >
                              TRN. 100386939100003
                            </p>
                          </td>
                          <td className="w_50 sm_wdth_4  text-center">
                            <img
                              src={Logo}
                              alt="Not found"
                              className="img-fluid invoiceLogo"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h3 className="invoiceTitle">Tax invoice</h3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table style={{ width: "100%", marginBottom: "0.5rem" }}>
                      <tbody>
                        <tr>
                          <td className="sm_wdth_4" style={{ width: "66%" }}>
                            <h4 style={{ fontWeight: "700", fontSize: "15px" }}>
                              BILL TO
                            </h4>
                            <p
                              style={{
                                fontSize: "15px",
                                marginBottom: "0.2rem",
                              }}
                            >
                              {" "}
                              {userInfo?.first_name} {userInfo?.last_name}
                            </p>
                            <p
                              style={{
                                fontSize: "15px",
                                marginBottom: "0.2rem",
                              }}
                            >
                              {" "}
                              {userInfo?.apartment}
                            </p>
                            <p
                              style={{
                                fontSize: "15px",
                                marginBottom: "0.2rem",
                              }}
                            >
                              {" "}
                              {userInfo?.mobile_no}
                            </p>
                            <p
                              style={{
                                fontSize: "15px",
                                marginBottom: "0.2rem",
                              }}
                            >
                              {paymentById?.building_name}
                            </p>
                            <p
                              style={{
                                fontSize: "15px",
                                marginBottom: "0.2rem",
                              }}
                            >
                              {" "}
                              {paymentById?.project_name}
                            </p>
                            <p
                              style={{
                                fontSize: "15px",
                                marginBottom: "0.2rem",
                              }}
                            >
                              {" "}
                              {paymentById?.emirate_name}
                            </p>
                          </td>
                          <td className=" sm_wdth_5" style={{ width: "54%" }}>
                            <p className="mb-1" style={{ fontSize: "15px" }}>
                              <strong
                                className="d-inline-block"
                                style={{
                                  whiteSpace: "nowrap",
                                  width: "60%",
                                  paddingLeft: "0.3rem",
                                }}
                              >
                                Invoice No:
                              </strong>
                              {paymentById?.invoice_number}
                            </p>
                            <p className="mb-1" style={{ fontSize: "15px" }}>
                              <strong
                                className="d-inline-block"
                                style={{
                                  whiteSpace: "nowrap",
                                  width: "60%",
                                  paddingLeft: "0.3rem",
                                }}
                              >
                                Date:
                              </strong>
                              {moment(paymentById?.date_of_payment).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                            <p className="mb-1" style={{ fontSize: "15px" }}>
                              <strong
                                className="d-inline-block"
                                style={{
                                  whiteSpace: "nowrap",
                                  width: "60%",
                                  paddingLeft: "0.3rem",
                                }}
                              >
                                Due Date:
                              </strong>
                              {moment(paymentById?.date_of_payment).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                            <p className="mb-1" style={{ fontSize: "15px" }}>
                              <strong
                                className="d-inline-block"
                                style={{
                                  whiteSpace: "nowrap",
                                  width: "60%",
                                  paddingLeft: "0.3rem",
                                }}
                              >
                                Terms:
                              </strong>
                              Net 30
                            </p>
                            <p className="mb-1" style={{ fontSize: "15px" }}>
                              <strong
                                className="d-inline-block"
                                style={{
                                  width: "60%",
                                  paddingLeft: "0.3rem",
                                }}
                              >
                                Payment Method:
                              </strong>
                              Stripe
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table style={{ width: "100%", marginBottom: "1.5rem" }}>
                      <tbody>
                        <tr>
                          <td
                            style={{ height: "2px", background: " #202e5a" }}
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table
                      style={{
                        width: "100%",
                        marginBottom: "1rem",
                        borderBottom: "1px dashed #cecece",
                        marginTop: "0rem",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              background: "#d2d5de",
                              color: "#202e5a",
                              borderRadius: "0",
                              textAlign: "center",
                              padding: "10px 10px",
                              whiteSpace: "nowrap",
                              fontSize: "15px",
                            }}
                          >
                            Date
                          </th>
                          <th
                            style={{
                              background: "#d2d5de",
                              color: "#202e5a",
                              borderRadius: "0",
                              textAlign: "center",
                              padding: "10px 10px",
                              whiteSpace: "nowrap",
                              fontSize: "15px",
                            }}
                          >
                            Description
                          </th>
                          <th
                            style={{
                              background: "#d2d5de",
                              color: "#202e5a",
                              borderRadius: "0",
                              textAlign: "center",
                              padding: "10px 10px",
                              whiteSpace: "nowrap",
                              fontSize: "15px",
                            }}
                          >
                            Notes
                          </th>
                          <th
                            style={{
                              background: "#d2d5de",
                              color: "#202e5a",
                              borderRadius: "0",
                              textAlign: "center",
                              padding: "10px 10px",
                              whiteSpace: "nowrap",
                              fontSize: "15px",
                            }}
                          >
                            QTY
                          </th>
                          {/* <th style={{ background: '#d2d5de', color: '#202e5a', borderRadius: '0', textAlign: 'center', padding: '10px 10px' }}>Rate</th> */}
                          <th
                            style={{
                              background: "#d2d5de",
                              color: "#202e5a",
                              borderRadius: "0",
                              textAlign: "center",
                              padding: "10px 10px",
                              whiteSpace: "nowrap",
                              fontSize: "15px",
                            }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              fontWeight: "500",
                              border: "0",
                              textAlign: "center",
                              padding: "10px 10px",
                              color: "black",
                              fontSize: "15px",
                            }}
                          >
                            {moment(paymentById?.date_of_payment).format(
                              "DD/MM/YYYY"
                            )}
                          </td>
                          <td
                            style={{
                              fontWeight: "500",
                              border: "0",
                              textAlign: "center",
                              padding: "10px 10px",
                              color: "black",
                              fontSize: "15px",
                            }}
                          >
                            Car Wash Service <br />
                            <span style={{ fontWeight: "700" }}>
                              {paymentById ? paymentById?.services : ""}
                            </span>
                          </td>
                          <td
                            style={{
                              fontWeight: "500",
                              border: "0",
                              textAlign: "center",
                              padding: "10px 10px",
                              color: "black",
                              fontSize: "15px",
                            }}
                          >
                            {paymentById ? paymentById?.description : "-"}
                          </td>
                          <td
                            style={{
                              fontWeight: "500",
                              border: "0",
                              textAlign: "center",
                              padding: "10px 10px",
                              color: "black",
                              fontSize: "15px",
                            }}
                          >
                            1
                          </td>
                          {/* <td style={{ fontWeight: '500', border: '0', textAlign: 'center', padding: '10px 16px', color: 'black', fontSize: '14px' }}>{paymentById?.payment_amount}</td> */}
                          <td
                            style={{
                              fontWeight: "500",
                              border: "0",
                              textAlign: "center",
                              padding: "10px 10px",
                              color: "black",
                              fontSize: "15px",
                            }}
                          >
                            AED {paymentById?.payment_amount}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table
                      style={{
                        width: "100%",
                        textAlign: "end",
                        borderBottom: "1px dashed rgb(206, 206, 206)",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <tbody style={{ paddingRight: "1.8rem" }}>
                        <tr>
                          <td style={{ fontSize: "15px", fontWeight: "700" }}>
                            Total:{" "}
                          </td>
                          <td
                            style={{
                              fontSize: "15px",
                              textAlign: "left",
                              paddingLeft: "0.4rem",
                            }}
                          >
                            <span
                              style={{ fontWeight: "700", paddingRight: "5px" }}
                            >
                              AED
                            </span>
                            {paymentById?.original_amount}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "15px", fontWeight: "700" }}>
                            Tip:{" "}
                          </td>
                          <td
                            style={{
                              fontSize: "15px",
                              textAlign: "left",
                              paddingLeft: "0.4rem",
                            }}
                          >
                            <span
                              style={{ fontWeight: "700", paddingRight: "5px" }}
                            >
                              AED
                            </span>{" "}
                            {paymentById?.tip}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "15px", fontWeight: "700" }}>
                            VAT Total(5%):
                          </td>
                          <td
                            style={{
                              fontSize: "15px",
                              textAlign: "left",
                              paddingLeft: "0.4rem",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "700",
                                paddingRight: "5px",
                                fontSize: "15px",
                              }}
                            >
                              AED
                            </span>{" "}
                            {paymentById?.total_vat}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "15px", fontWeight: "700" }}>
                            Total Amount:
                          </td>
                          <td
                            style={{
                              fontSize: "15px",
                              textAlign: "left",
                              paddingLeft: "0.4rem",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "700",
                                paddingRight: "5px",
                                fontSize: "15px",
                              }}
                            >
                              AED
                            </span>{" "}
                            {paymentById?.payment_amount}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table style={{ width: "100%", marginTop: "1rem" }}>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              color: "black",
                              marginBottom: "1rem",
                              fontSize: "15px",
                              fontWeight: "600",
                            }}
                          >
                            Bank Details
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "15px",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            Account Name: Kenzie Building Cleaning Services EST
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "15px",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            Branch Name: Emirates Islamic Bank
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "15px",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            Branch Address: Al Barsha
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "15px",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            Bank Account Number: 389320492389
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "15px",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            IBAN Number: AE897347348354324
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "15px",
                              paddingBottom: "0.4rem",
                            }}
                          >
                            Swift Code: MEBLAEAD
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="btnWrapper pb-2">
            <button onClick={exportPdf} className="downloadPDFBtn">
              Download PDF
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Invoice;
