import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Typography, Checkbox } from 'antd';
import { image } from "../Helper";
import Container from "react-bootstrap/Container";
import { InfoCircleOutlined } from "@ant-design/icons";
import PaymentForm from "../Components/Form/PaymentForm";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import * as UserService from "../Services/UserService/UserService";
import Http from "../Http";
import { Url } from "../Helper";
import swal from "sweetalert";
import { MdOutlineLocationOn } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
const { Title } = Typography;

const Payment = () => {
    let backgroundImage = localStorage?.getItem('bgImage');
    const [loader, setLoader] = useState(false);
    const [paymentDisabled, setPaymentDisabled] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState();
    const [data, setData] = useState({});
    const [actualTip, setAcutalTip] = useState(0);
    const [errorMsg, setErrorMsg] = useState("");
    const [descriptionText, setDescriptionText] = useState();
    const [userDetails, setUserDetails] = useState([]);
    const [buildingName, setBuildingName] = useState("");
    const [selectedService, setSelectedService] = useState();
    const [monthlyReminder, setMonthlyReminder] = useState(1);
    const [userName, setUserName] = useState();
    const [UserEmail, setUserEmail] = useState();
    const [paymentAmount, setPaymentAmount] = useState();
    const [completeData, setCompleteData] = useState([]);
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    let basicAmount = paymentAmount + actualTip;
    let vatAmount = (paymentAmount * 5) / 100;
    let totalAmount = parseFloat(basicAmount) + parseFloat(vatAmount);
    let summaryService = 1;
    let original_qr = localStorage.getItem("QRverify");
    let projectName = 1;
    let emirateName = 1;
    const queryParams = new URLSearchParams(window.location.search);
    const customerIDdata = queryParams.get('customer_id');
    const customerIDdataNew = queryParams.get('inv');

    const myStyle = {
        backgroundImage: `url(${backgroundImage ? image + backgroundImage : ""})`,
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    useEffect(() => {
        localStorage?.clear();
        if (customerIDdataNew !== null || customerIDdata !== null) {
            setLoader(true);
            localStorage.setItem("ExistingDataFetching", true);
            let formData = {
                id_value: customerIDdataNew !== null ? customerIDdataNew : customerIDdata,
                id_type: customerIDdataNew !== null ? 'invoice' : 'customer',
            }
            dispatch(UserService.check_existing_user_byID(formData))
                .then((response) => {
                    setUserName(response?.full_name);
                    setUserEmail(response.email);
                    if (response?.customer_invoices) {
                        setPaymentAmount(parseFloat(response?.customer_invoices[0]?.amount));
                    } else {
                        setPaymentAmount(parseFloat(response?.payment[0]?.original_amount));
                    }
                    var form = {
                        email: response?.email,
                        first_name: response?.first_name,
                        mobile_no: response?.mobile_no,
                        last_name: response?.last_name,
                        building_name: response?.building_name,
                        apartment: response?.apartment,
                        original_qr_code: response?.original_qr_code,
                    };
                    setUserDetails(form);
                    setLoader(false);
                    setCompleteData(response);
                    setBuildingName(response?.building_name);
                    if (customerIDdataNew !== null) {
                        setMonthlyReminder(false);
                    } else {
                        setMonthlyReminder(response?.monthly_reminder);
                    }
                    dispatch(UserService.getServiceList())
                        .then((res) => {
                            if (response?.service === null || response?.service === undefined || response?.service === '') {
                                let serviceID = response?.customer_invoices ? response?.customer_invoices[0]?.service_id : response?.service_id;
                                let findService = res?.find((item) => item.id === serviceID);
                                setSelectedService(findService);
                            } else {
                                setSelectedService(response?.service_id);
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                })
                .catch((err) => {
                    setLoader(false);
                });
        }
        // eslint-disable-next-line
    }, []);

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                lineHeight: "27px",
                color: "#212529",
                fontSize: "1.1rem",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };

    const updateData = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
        localStorage?.setItem("Amount", data?.amount);
        if (e.target.name === "amount") {
            setPaymentAmount(parseInt(e.target.value));
        } else if (e.target.name === "tip") {
            setAcutalTip(parseInt(e.target.value))
        } else if (e.target.name === "message") {
            setDescriptionText(e.target.value);
        }
    };

    const handleSubmit = async (event) => {
        setPaymentDisabled(true);
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        setErrorMsg("");
        const paymentMethodObj = {
            type: "card",
            card: elements.getElement(CardNumberElement),
            billing_details: {
                name,
                email,
            },
        };

        let user_email = email ? email : UserEmail;
        let user_building = buildingName
            ? buildingName
            : userDetails?.building_name;
        let description = completeData?.customer_invoices ? completeData?.customer_invoices[0]?.pay_note : descriptionText;
        let userService = selectedService?.id ? selectedService?.id : selectedService;
        let type = customerIDdataNew !== null ? 'invoice' : 'customer';

        const payment_user_info = {
            ...data,
            user_email,
            user_building,
            projectName,
            emirateName,
            description,
            original_qr,
            userService,
            type
        };

        const paymentMethodResult = await stripe.createPaymentMethod(
            paymentMethodObj
        );

        stripePaymentMethodHandler(
            {
                payment_data: payment_user_info,
                result: paymentMethodResult,
                amount: totalAmount,
                description: completeData?.customer_invoices ? completeData?.customer_invoices[0]?.pay_note : descriptionText,
                vat: vatAmount,
                OriginalAmount: paymentAmount,
                userService: selectedService?.id ? selectedService?.id : selectedService,
                type: type,
                monthly_reminder: monthlyReminder === true ? 1 : 0,
            },
            handleResponse
        );
    };

    const stripePaymentMethodHandler = async (data, cb) => {
        setLoader(true);
        const { payment_data, result, vat } =
            data;
        if (result.error) {
            cb(result);
        } else {
            const paymentResponse = await stripePayment({
                payment_method_id: result.paymentMethod.id,
                name: result.paymentMethod.billing_details.name,
                email: result.paymentMethod.billing_details.email
                    ? result.paymentMethod.billing_details.email
                    : UserEmail,
                amount: totalAmount,
                tip: payment_data.tip ? payment_data.tip : "0",
                emirate_name: payment_data.emirate_name,
                project_name: payment_data.project_name,
                user_email: payment_data.user_email,
                user_building: payment_data.user_building,
                description: completeData?.customer_invoices ? completeData?.customer_invoices[0]?.pay_note : data?.description ? data?.description : "Car Wash Service",
                TotalVat: vat,
                OriginalAmount: paymentAmount,
                original_qr: original_qr,
                userService: selectedService?.id ? selectedService?.id : selectedService,
                type: customerIDdataNew !== null ? 'invoice' : 'customer',
                monthly_reminder: monthlyReminder === true ? 1 : 0,
            });
            cb(paymentResponse);
        }
    };

    // place backend API call for payment
    const stripePayment = async (data) => {
        new Promise((resolve, reject) => {
            Http.post(Url + "/api/pay", data)
                .then((res) => {
                    if (res.status === 200) {
                        handlePaymentSuccess();
                    }
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };

    const handleResponse = (response) => {
        if (response.error) {
            setErrorMsg(
                typeof response.error === "string"
                    ? response.error
                    : response.error.message
            );
            setLoader(false);
            return;
        }
        setPaymentDisabled(false);
    };

    const handlePaymentSuccess = () => {
        setTimeout(() => {
            swal({
                title: "Your payment has been successfully processed.",
                text: "A copy of your Invoice and Receipt has been sent to your email.",
                icon: "success",
            }).then(function () {
                localStorage.clear();
                if (customerIDdata !== null) {
                    localStorage?.setItem('email', UserEmail);
                    navigate('/customer_dashboard')
                } else {
                    window.location.href = 'https://kenzieclean.ae/';
                }

            });
            setLoader(false);
            setData(null);
        }, 2000);
    };

    const onChangeReminder = (e) => {
        setMonthlyReminder(e.target.checked);
    };

    return (
        <div className='paymentWrapperComponent' style={myStyle}>
            <Container className='customContainer'>
                <div className='HeaderWrapper'>
                    <h5>Welcome, {userName}</h5>
                </div>
                <Card className='customCard'>
                    <form onSubmit={handleSubmit} >
                        <Row>
                            <Col md={8} className='LeftSection'>
                                <div className='amountSection'>
                                    <h5>Enter Amount</h5>
                                    <input
                                        name="amount"
                                        type="number"
                                        onBlur={updateData}
                                        required
                                        placeholder='Enter amount'
                                        defaultValue={paymentAmount}
                                        readOnly={customerIDdataNew !== null ? true : false}
                                    />
                                    <div className="tipInfo">
                                        <InfoCircleOutlined />
                                        <p>
                                            This total amount is excluding 5% VAT.
                                        </p>
                                    </div>
                                </div>
                                <div className='tipSection mt-1'>
                                    <h5>Enter Tip</h5>
                                    <input
                                        name="tip"
                                        type="number"
                                        onChange={updateData}
                                        min="0"
                                        placeholder='Enter tip'
                                    />
                                    <div className="tipInfo">
                                        <InfoCircleOutlined />
                                        <p>
                                            100% of the tip goes to your cleaner directly. Thank you
                                            for your generosity!
                                        </p>
                                    </div>
                                </div>
                                <div className="Message mt-1">
                                    <textarea
                                        className="customTextarea"
                                        name="message"
                                        rows={5}
                                        placeholder="Please specify the services you are paying for, including the service month(s), number of cars, service package, and any other relevant details."
                                        onChange={updateData}
                                        maxLength={100}
                                        value={completeData?.customer_invoices && completeData?.customer_invoices[0]?.pay_note}
                                        readOnly={customerIDdataNew !== null ? true : false}
                                    />
                                </div>
                            </Col>
                            <Col md={16} className='rightSection'>
                                <div className='paymentFormSection'>
                                    <PaymentForm
                                        userDetails={userDetails}
                                        project_name={projectName}
                                        emirate_name={emirateName}
                                        description={descriptionText}
                                        Amount={paymentAmount}
                                        Tip={actualTip}
                                        service={summaryService}
                                    />
                                </div>
                                <div className="OrSection">
                                    <span>OR</span>
                                </div>
                                <hr />
                                <div className='cardWrapper'>
                                    <div className="StripeHeader">
                                        <Title level={4}>Enter your Card details</Title>
                                    </div>
                                    <div className="row stripeFormDetails">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="cc-name">Name on card</label>
                                            <input
                                                id="cc-name"
                                                type="text"
                                                className="form-control"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="cc-email">Email</label>
                                            <input
                                                id="cc-email"
                                                type="text"
                                                className="form-control"
                                                value={UserEmail ? UserEmail : email ? email : ""}
                                                required
                                                onChange={(e) => setEmail(e.target.value)}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="row stripeFormDetails">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="cc-number">Card Number</label>
                                            <CardNumberElement
                                                id="cc-number"
                                                className="form-control"
                                                options={CARD_ELEMENT_OPTIONS}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row stripeFormDetails">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="expiry">Valid Upto</label>
                                            <CardExpiryElement
                                                id="expiry"
                                                className="form-control"
                                                options={CARD_ELEMENT_OPTIONS}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="cvv">CVV</label>
                                            <CardCvcElement
                                                id="cvc"
                                                className="form-control"
                                                options={CARD_ELEMENT_OPTIONS}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <Row className="stripeFormDetails totalAmountRow">
                                        <Col md={24} xs={24}>
                                            <label>Total Amount</label>
                                            <div
                                                className={
                                                    totalAmount
                                                        ? "AmountAdded totalAmountSection"
                                                        : "totalAmountSection"
                                                }
                                            >
                                                {totalAmount ? totalAmount : "Total Amount"}
                                            </div>
                                            <div className="infoCol">
                                                <InfoCircleOutlined />
                                                Total Amount after adding tip and VAT.
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={24}>
                                            <div className="reminderCheckboxWrapper">
                                                <Checkbox onChange={onChangeReminder} checked={monthlyReminder}>I would like to receive a monthly email reminder for my upcoming payment due date.</Checkbox>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="bottomBtnContainer">
                                    {loader ? (
                                        <button className="btn btn-dark paymentBtn" disabled>
                                            <div
                                                className="spinner-border spinner-border-sm text-light "
                                                style={{ marginRight: "0.5rem" }}
                                                role="status"
                                            ></div>
                                            Processing...
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="btn btn-dark paymentBtn"
                                            disabled={paymentDisabled}
                                        >
                                            Confirm & Pay
                                        </button>
                                    )}
                                </div>
                                {errorMsg && (
                                    <div className="text-danger mt-2">{errorMsg}</div>
                                )}
                            </Col>
                        </Row>
                    </form>
                </Card>
                <div className='bottomWrapper'>
                    <MdOutlineLocationOn /><span>{buildingName}</span>
                </div>
            </Container>
        </div>
    )
}

export default Payment