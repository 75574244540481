import React, { useEffect, useState } from "react";
import Sidebar from "../../../Containers/Customer/Sidebar";
import { Row, Col, Card, Typography, Table, Button, Spin } from "antd";
import { FcMoneyTransfer, FcPaid } from "react-icons/fc";
import { useDispatch } from "react-redux";
import * as UserService from "../../../Services/UserService/UserService";
import Backdrop from "@mui/material/Backdrop";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;

const CustomerDashboard = () => {
  // const [page, setPage] = useState(1);
  const user_email = localStorage.getItem("email");
  const [paymentInfo, setPaymentInfo] = useState([]);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let page = 1;

  useEffect(() => {
    setLoader(true);
    setOpen(true);
    dispatch(UserService.getUserProfile(user_email)).then((res) => {
      setPaymentInfo(res.payment);
      setLoader(false);
      setOpen(false);
    });
  }, []);

  const columns = [
    {
      title: "S.No",
      dataIndex: "sNo",
      key: "sNo",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      fixed: "left",
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
    },
    {
      title: "Date",
      dataIndex: "date_of_payment",
      key: "date_of_payment",
    },
    {
      title: "Amount",
      dataIndex: "payment_amount",
      key: "payment_amount",
      render: (text, record) => {
        return text?.toLocaleString();
      },
    },
    {
      title: "Building",
      dataIndex: "building_name",
      key: "building_name",
    },
    {
      title: "Project",
      dataIndex: "project_name",
      key: "project_name",
    },
  ];

  const PaymentPage = () => {
    navigate("/customer_payment");
  };

  let totalAmount = paymentInfo.reduce((a, v) => (a = a + v.payment_amount), 0);
  let totalTip = paymentInfo.reduce((a, v) => (a = a + v.tip), 0);

  return (
    <>
      <Sidebar />
      <div className="customerDashboardWrapper">
        <Row>
          <Col xs={24} md={11}>
            <Card>
              <FcPaid />
              <Title level={2}>AED {totalAmount?.toLocaleString()}</Title>
              <Title level={4}>Total Amount</Title>
            </Card>
          </Col>
          <Col xs={24} md={11}>
            <Card>
              <FcMoneyTransfer />
              <Title level={2}>AED {totalTip?.toLocaleString()}</Title>
              <Title level={4}>Total Tip</Title>
            </Card>
          </Col>
          <Col xs={24} md={24}>
            <Card className="tableCard">
              <Title level={4}>Recent Payments</Title>
              <div className="tableWrapper">
                {loader === true ? (
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={open}
                  >
                    <Spin />
                  </Backdrop>
                ) : (
                  <Table
                    dataSource={
                      paymentInfo ? (
                        paymentInfo
                      ) : (
                        <Backdrop
                          sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                          }}
                          open={open}
                        >
                          <Spin />
                        </Backdrop>
                      )
                    }
                    columns={columns}
                    pagination={false}
                    showSorterTooltip={false}
                    rowKey={(record) => record.paymentInfo}
                  />
                )}
              </div>
              <div className="buttonWrapper">
                <Button onClick={PaymentPage}>See More</Button>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CustomerDashboard;
