import { Button, Drawer, Row, Col, Typography, Image, Menu } from "antd";
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import Logo from "../../Assets/Images/LoginLogo.png";
import { BiUser } from "react-icons/bi";
import { CiLogout } from "react-icons/ci";
import { RiBillLine } from "react-icons/ri";
// import { AiOutlineHome } from "react-icons/ai";
import { SlScreenDesktop } from "react-icons/sl";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const TabletDashboard = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  let qr_token = localStorage.getItem("QRverify");

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const resetValues = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("email");
    localStorage.removeItem("user_name");
    localStorage.removeItem("token");
    localStorage.setItem("isLoggedIn", false);
    localStorage.setItem("passwordEntered", false);
    // localStorage.clear(); //for localStorage
    sessionStorage.clear(); //for sessionStorage
    navigate("/login");
    window.location.reload();
  };
  const menuItems = [
    {
      key: "Home",
      icon: <SlScreenDesktop />,
      label: "Back To Main Menu",
      target: "/" + "?callback=" + qr_token,
    },
    // {
    //     key: 'customer_dashboard',
    //     icon: <AiOutlineHome />,
    //     label: 'Dashboard',
    //     target: '/customer_dashboard'
    // },
    {
      key: "customer_profile",
      icon: <BiUser />,
      label: "Profile",
      target: "/customer_profile",
    },
    {
      key: "customer_payment",
      icon: <RiBillLine />,
      label: "Payment History",
      target: "/customer_payment",
    },
  ];
  const handleMenuClick = ({ key }) => {
    const { target } = menuItems.find((item) => item.key === key) || {};
    if (target) {
      navigate(target);
    }
  };
  const RedirectToHome = () => {
    navigate("/" + "?callback=" + qr_token);
  };

  return (
    <>
      <div className="sidebarWrapper">
        <Row>
          <Col xs={24} md={24}>
            <div className="navbarWrapper">
              <Title level={4}>
                <button onClick={RedirectToHome} className="headerbtn">
                  Welcome To Kenzie's
                </button>
              </Title>
              <Button type="primary" onClick={showDrawer} className="drawerBtn">
                <GiHamburgerMenu />
              </Button>
            </div>
          </Col>
        </Row>
        <Drawer
          placement="left"
          closable={false}
          onClose={onClose}
          open={open}
          className="drawerWrapper"
          width={300}
        >
          <div className="drawermenu">
            <Row>
              <Col xs={24} md={24} className="imgRow">
                <div className="logoSection">
                  <Image
                    src={Logo}
                    alt="Not found"
                    className="img-fluid"
                    preview={false}
                  />
                </div>
              </Col>
              <Col xs={24} md={24}>
                <div className="drawerSubMenu">
                  <Menu
                    mode="inline"
                    className="mobileMenu"
                    items={menuItems}
                    onClick={handleMenuClick}
                  ></Menu>
                </div>
              </Col>
            </Row>
            <Row className="footerContent">
              <Col xs={24} md={24}>
                <Button onClick={resetValues} className="logOutBtn">
                  <CiLogout />
                  Log Out
                </Button>
              </Col>
            </Row>
          </div>
        </Drawer>
      </div>
    </>
  );
};
export default TabletDashboard;
